<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4 d-flex justify-content-between">
        <span>Qna Reply</span>
        <button class="btn btn-primary btn-sm" @click="readChk">읽었어요</button>
      </h4>
      <div class="text-muted mb-3 pb-4" v-html="item.desc"></div>
      <div class="float-end mt-2">
        <router-link v-if="type === 'qna'" :to="`/qna/modify/${item.pqIdx}`" class="text-primary">
          <i class="mdi mdi-arrow-right h5"></i>
        </router-link>
        <router-link
          v-if="type === 'work'"
          :to="`/homework/detail/${item.mqdIdx}`"
          class="text-primary"
        >
          <i class="mdi mdi-arrow-right h5"></i>
        </router-link>
      </div>
      <h6 class="mb-0">
        <!-- <img
                  src="@/assets/images/users/user-3.jpg"
                  alt=""
                  class="avatar-sm rounded-circle me-2"
                /> -->
        {{ item.mbId }}
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    type: {
      type: String,
      default() {
        return '';
      }
    }
  },
  methods: {
    async readChk() {
      const res = await this.$store.dispatch('post/replyReadConfirm', {
        idx: this.item.idx,
        type: this.type
      });

      if (res > 0) {
        this.$emit('getReplyList');
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
