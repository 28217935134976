<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                질문 & 리뷰 관리
              </h4>
              <p class="card-title-desc">현재 남겨진 댓글 또는 리뷰를 한 번에 관리합니다.</p>

              <div class=" mg-b-10 fr">
                <form class="d-flex" @submit.prevent="getPostListSearch">
                  <select v-model="type" class="form-control mg-r-5">
                    <option value="">유형선택</option>
                    <option value="qna">QNA</option>
                    <option value="work">HomeWork</option>
                  </select>
                  <button class="btn btn-primary btn-sm me-2" type="submit">
                    검색
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div v-for="item in list" :key="item.idx" class="col-lg-6 col-md-12">
          <replyList :item="item" :type="type" @getReplyList="getReplyList" />
        </div>

        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="flex-jac">
                <b-pagination
                  v-model="pageData.page"
                  :total-rows="pageData.totalCnt"
                  :per-page="pageData.perPage"
                  @page-click="pageClick"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </div>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import replyList from '@/components/reply/replyList.vue';

export default {
  page: {
    title: 'Home',
    meta: [{name: 'description', content: appConfig.description}]
  },
  components: {
    Layout,
    PageHeader,
    replyList
  },
  data() {
    return {
      title: 'Reply List',
      items: [
        {
          text: 'Sucoding',
          href: '/'
        },
        {
          text: 'Homework',
          href: '/'
        },
        {
          text: 'Reply List',
          active: true
        }
      ],
      type: 'qna',
      serachKeyword: '',
      list: [],
      submitYn: 'Y',
      pageData: {
        page: 1, // 현재 페이지
        perPage: 10, // 페이지 초기 갯수
        totalCnt: 100, // 전체 갯수
        pageStartIndex: 0 // 페이지 시작 번호
      }
    };
  },
  created() {
    this.type = this.$store.state.qna.type;
  },
  mounted() {
    this.getReplyList();
  },
  watch: {
    async type() {
      this.$store.commit('qna/SET_TYPE', this.type);
    }
  },
  methods: {
    readChk() {
      console.log('read');
    },
    pageClick(button, page) {
      this.pageData.page = page;
      this.$store.commit('post/SET_PAGE', this.pageData.page);
      this.getReplyList();
    },
    async getReplyList() {
      await this.$store
        // 유저가 풀어낸 퀴즈
        .dispatch('post/getReplyNoRead', {
          page: this.pageData.page ? this.pageData.page : 1,
          limit: this.pageData.perPage,
          type: this.type,
          orderKey: 'p_order_num',
          orderAction: 'desc'
        })
        .then(data => {
          this.list = data.list;
          this.pageData.totalCnt = data.totalCnt;
          this.pageData.pageStartIndex =
            this.pageData.totalCnt - (this.pageData.page - 1) * this.pageData.perPage;
        });
    },
    getPostListSearch() {
      this.pageData.page = 1;
      this.getReplyList();
    },
    async deleteHomework(idx) {
      this.$confirm({
        message: `제출한 코드를 삭제하시겠습니까?`,
        button: {
          no: '취소',
          yes: '삭제'
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: async confirm => {
          if (confirm) {
            // ... do something

            await this.$store
              .dispatch('post/deleteHomework', {
                idx
              })
              .then(res => {
                this.$confirm({
                  title: res.result ? 'Success' : 'Error',
                  message: res.message,
                  button: {
                    yes: '확인'
                  }
                });
                if (res.result) {
                  this.getPostList();
                }
              });
          }
        }
      });
    }
  }
};
</script>

<style scoped>
table th,
table td {
  text-align: center;
}
form input,
form select {
  min-width: 80px;
  margin-right: 10px;
}
form button {
  min-width: 50px;
}
</style>
